import React from "react"
import { graphql, Link } from "gatsby"
import { SiteMetadata, QuoteCarousel } from "../components"
import { IndexSVG, WavesSVG } from '../components/SVG/'
import { Layout } from "../layouts/Layout"
import {Container, Row, Col} from "react-bootstrap"
import Img from "gatsby-image"

import { FaInstagram, FaChevronRight } from "react-icons/fa"

export default ({ data }) => {
  
  return (
    <Layout noWaveHeader>
      <SiteMetadata
        title="Натуральный мед, экопродукты и экскурсии"
        description="Пчеловодство - наш семейный бизнес уже 3 поколения, поэтому у нас всегда можно купить натуральный мед, шоколад, свечи, косметику ручной работы и другие продукты пчеловодства."
        canonical="/"
      />
      <Container fluid className="blue">
        <Container>
          <Row className="justify-content-lg-center">
            <Col md={{span: 6, order: 12}} lg={5} className="align-self-center my-3 text-center">
              <h1 className="beeright d-inline">Только пчелы, только натуральный МЁД!</h1>
              <h3 className="mt-3">А так же шоколад, свечи, мыло ручной работы и другие милости-приятности!</h3> 
            </Col>
            <Col md={6} lg={5}>
              <Img fluid={data.hero.childImageSharp.fluid} alt="натуральный мед в сотах"/>
            </Col>        
          </Row>
        </Container>        
      </Container>       
      <WavesSVG down/>

      <Container>
        <Row className="text-center text-md-left justify-content-center">
          <Col xs={12} md xl={{offset: 1}} className="align-self-center">
            <h4 className="title-container my-4">Кто мы?</h4>
            <div>
              <p>
              Пчелиный Хуторок - это уникальная экскурсионная пасека, а также натуральный мед и продукты, сделанные самой природой. Пчеловодство — наш семейный бизнес уже 3 поколения, поэтому вы всегда можете быть уверены в качестве нашего меда и продуктов. Мы любим пчел и знаем о них все.
              </p>
              <p>
                Приобретая продукцию наших пчел-тружениц вы рискуете стать здоровее и счастливее. А наши экскурсии и мастер-классы могут сделать вас ярым фанатом пчелиного семейства.. возможно вы не сможете себе отказать в собственном маленьком улье.
              </p>
              <Link className="btn btn-primary rounded px-3 my-3" to="/about/">Подробнее <FaChevronRight className="icon-svg"/></Link>
            </div>
          </Col>
          <Col xs={6} md={4} lg={{offset : 1}} className="align-self-center">
            <Img fluid={data.about.childImageSharp.fluid} alt="медовый ковш"/>
          </Col> 
        </Row>
        <Row className="d-flex justify-content-center mt-5">
          <Col md={10} xl={8}>
            <Row>
              <Col>
                <IndexSVG/>                
              </Col>
              <Col>
                <IndexSVG kg/>
              </Col>
            </Row>
          </Col>
          
        </Row>
      </Container>

      <WavesSVG up/>
      <Container fluid className="blue position-relative">
        <Container>
          <Row className="pt-5 d-flex justify-content-center">            
            <Col md={10} className="text-center">
              <h2 className="title-container d-inline-block mb-5 beeright-small position-relative">Натуральный мед от настоящих пчел!</h2>  
              <p>
                Наши пасеки находятся в Псковской области России. 
                Это прекрасное место с лугами, полями и лесами не тронутыми человеком, поэтому наш мед самый натуральный и чистый.
                Он несет в себе всю силу и разнообразие цветущих растений средней полосы России, коих насчитывается более 50.
              </p>
              <p className="mb-5">
                Ассортимент нашей продукции представлен не только медом нескольких видов, но и другими продуктами пчеловодства.
                Свечи из вощины, шоколад с добавлением меда, натуральная косметика, готовые подарочные наборы - то, что вы всегда можете найти в Пчелином Хуторке. 
              </p>  
              <Link className="btn btn-primary btn-lg rounded mx-3 mb-3" style={{minWidth: `200px`}} to="/shop/">Все товары</Link>      
              <Link className="btn btn-primary btn-lg rounded mx-3 mb-3" style={{minWidth: `200px`}} to="/shop/med/">Больше о меде</Link>  
            </Col>
          </Row>          
          <Row className="justify-content-center">
            <Col lg={8}  xl={7} className="mt-5 d-flex align-items-end justify-content-center">
              <Img fluid={data.honeys.childImageSharp.fluid} alt="натуральный мед с пасеки" className="w-100"/>
            </Col>  
          </Row>
        </Container>
        <WavesSVG up white className="position-absolute w-100 l-0 r-0 b-0"/>
      </Container>
      
      <Container className="py-5 mt-5">
        <Row>

          <Col md={{span:6, mt: 0, order: 'last'}} lg={{span: 5, offset: 1 }} className="text-center text-md-right d-flex align-items-center">
            <div>
              <h3 className="title-container my-5">Экскурсии на пасеку и мастер-классы</h3>
              <div>
                <p>
                  Экскурсия на пасеку в сопровождении опытного увлеченного пчеловода в ходе которой вы в живую познакомитесь с пчелиным государством, продегустируете продукты, которые производят пчелы, узнаете удивительные факты о них и тонкостях ремесла, а так же подзарядитесь отличным настроением и энергией апидомика. 
                </p>  
              </div>
              <Link className="btn btn-primary rounded px-3 my-3" to="/paseka/">Подробнее <FaChevronRight className="icon-svg"/></Link>
            </div>              
          </Col>

          <Col md={6} className="align-content-center justify-content-center mt-5 my-md-auto">
            <Img fluid={data.paseka.childImageSharp.fluid} alt="экскурсия на пасеку"/>
          </Col> 

        </Row>
      </Container> 
      <WavesSVG up/>
      <Container fluid className="blue">
        <Container>
          <Row>
            <Col className="d-flex mb-2 justify-content-center">
              <h3 className="title-container d-inline-block my-5 beeright-small position-relative">Пчелиный блог</h3>
            </Col>
          </Row>
          <Row>
            {data.posts.nodes.map((post, i) => (<Post {...post} i={i} key={`post_${i}`}/>))}         
          </Row>
          <Row className="d-flex justify-content-center mt-4">
              <Link className="btn btn-primary btn-lg rounded mx-3 mb-3" to="/blog/">Все статьи</Link> 
          </Row>
        </Container>
      </Container> 
      <WavesSVG down/>    
      <Container>
        <Row  className="text-center">
          <Col xs={12} md xl={{span: 4, offset : 1}}>
            <h3 className="title-container mb-5">Отзывы о нас</h3>   
            <p className="mb-5">
              Залог качественного  роста - мнение наших клиентов.
              Чем честнее вы будете с нами - тем лучше.
              Нас волнует мнение каждого клиента и мы хотим совершенствоваться!
            </p>
            <QuoteCarousel/> 
          </Col> 
          <Col md xl={{span : 5, offset : 1}}>
            <h3 className="title-container mb-5">Мы в Instagram</h3>            
            <InstImages defImages={[data.inst1, data.inst2, data.inst3, data.inst4]} className="mx-5 mx-md-0" link={data.social.meta.links.instagram}/>
            <a href={data.social.meta.links.instagram} rel="noreferrer noopener nofollow" target="_blank" className="btn btn-primary mt-3 rounded">
              <FaInstagram className="icon-svg"/> Подписаться
            </a>
          </Col> 
        </Row>
      </Container>   
    </Layout>
  )
}

const Post = ({i, img, title, slug, shortdesc}) => {
  let className
  if(i === 0) className = "d-none d-sm-block"
  if(i === 1) className = "d-none d-md-block"
  return(
    <Col sm={6} md={4} className={className}>
      <div className="card h-100">
          <Img fluid={img.childImageSharp.fluid} className="card-img-top" alt={title}/>
          <div className="card-body px-2 py-3 text-center">
              <Link to={`/blog/${slug}/`} className="stretched-link"><h3 className="card-title mb-0" style={{minHeight: "60px"}}>{title}</h3></Link>
              <p className="card-text mt-3">{shortdesc}</p>
          </div>
      </div>
    </Col>
  )
}

const InstImages = ({defImages, className, link}) => {
  return (
    <Row className={className}>
      {defImages.map((item,i) => (
        <Col xs={6} className="p-2" key={`inst_${i}`}>
          <a href={link} rel="noreferrer noopener nofollow" target="_blank">
            <Img fluid={item.childImageSharp.fluid} className="rounded" alt="натуральные продукты пчеловодства"/>
          </a>          
        </Col>      
        ))}
    </Row>
  )
}

export const query = graphql`
  query IndexQuery {
    posts: allBlogJson(limit: 3) {
      nodes {
        shortdesc
        slug
        title
        img {
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 350, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }  
    social: site {
      meta: siteMetadata {
        links {
          instagram
        }
      }
    }
    hero: file(relativePath: { eq: "images/banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 425) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    about: file(relativePath: {eq: "images/banner-about.jpg"}){
      childImageSharp {
        fluid(maxWidth: 425) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    paseka: file(relativePath: {eq: "images/paseka.jpg"}){
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    inst1: file(relativePath: {eq: "images/inst/inst-1.jpg"}){
      ...squareImageInst
    }
    inst2: file(relativePath: {eq: "images/inst/inst-2.jpg"}){
      ...squareImageInst
    }
    inst3: file(relativePath: {eq: "images/inst/inst-3.jpg"}){
      ...squareImageInst
    }
    inst4: file(relativePath: {eq: "images/inst/inst-4.jpg"}){
      ...squareImageInst
    }
    honeys: file(relativePath: {eq: "images/honeys.png"}){
      childImageSharp {
          fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_withWebp
          }
      }
    } 
  }
`
export const squareImageInst = graphql`
  fragment squareImageInst on File {
    childImageSharp {
      fluid(maxWidth: 255, maxHeight: 255) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`